









































































































@import '../../utilities/config';

.a-input {
  input {
    &::placeholder {
      color: gray('gray-150');
      font-weight: 300;
    }
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
