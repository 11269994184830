














@import '../../utilities/config';

.a-checklist {
  list-style: none;
  padding-left: 1.5rem;

  &-item {
    position: relative;
    color: gray('gray-700');
    margin-bottom: 0.5rem;

    &::before {
      background-image: url(../../assets/check_green.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 1rem;
      height: 1.5rem;
      margin-left: -1.5rem;
    }
  }
}
