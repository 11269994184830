






























































@import '../../utilities/config';

.a-password {
  &__input {
    position: relative;
  }
  &__showhide {
    display: block;
    position: absolute;
    right: 2px;
    bottom: 0;
    height: $input-height;
    width: 32px;
    outline: 0;
    border: 0;
    background-color: transparent;
    background-image: url(../../assets/eye_show.svg);
    background-position: center center;
    background-size: 16px auto;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }

    &--hide {
      background-image: url(../../assets/eye_hide.svg);
    }
  }
}
