





























@import '../../utilities/config';

.m-login-message {
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__title {
    margin: 18px 0;
  }
}
